import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConsultaRequest } from 'src/app/shared/models/consultaRequest';
import { emBlue, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {




  constructor(private http: HttpClient) {
  }
 
  getFacultyByLocation(sede:any) {
    return this.http.get(`${environment.apiUrl}/api/venue/get-by-region-ingreso/?regionId=${sede}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  preUniversityGetAll() {
    return this.http.get(`${environment.apiUrl}/api/career/pre-university/get-all/`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
    getCarrerByTitle(carrer: string, venue: string, language: string) {
    return this.http.get(`${environment.apiUrl}/api/career/get-by-title/?q=${carrer}&venue=${venue}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getCarrerByTitle2(carrer: string,  language: string) {
    return this.http.get(`${environment.apiUrl}/api/career/get-by-title/?q=${carrer}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getFaresCarrer(carrer: string) {
    return this.http.get(`${environment.apiUrl}/api/fare/?q=${carrer}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getPages(venue, language) {
    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=${venue}&locale=${language}&isVenue=1`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getVenueEntity(route: string, language) {
    return this.http.get(`${environment.apiUrl}/api/${route}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getEventsByMonth(language: string, month: number, year: number, venue: string) {

    if (venue == "") {
      return this.http.get(`${environment.apiUrl}/api/event/?locale=${language}&month=${month}&year=${year}`).pipe(tap(
        (res: any) => {
          return res;
        })
      );
    } else {
      return this.http.get(`${environment.apiUrl}/api/event/?locale=${language}&month=${month}&year=${year}&venue=${venue}`).pipe(tap(
        (res: any) => {
          return res;
        })
      );
    }
  }

  getEvent(title: string, language: string) {
    return this.http.get(`${environment.apiUrl}/api//event/get/?q=${title}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getRelationPosts(language: string) {
    return this.http.get(`${environment.apiUrl}/api//post/relation-posts/?locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getHomeEntities(language) {
    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=home&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getHomeEntity(route: string, language) {
    return this.http.get(`${environment.apiUrl}/api/${route}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getItemsHeader(language) {
    return this.http.get(`${environment.apiUrl}/api/menu/get/?locale=es&menu=Cabecera`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getItemsFooter(language) {
    return this.http.get(`${environment.apiUrl}/api/menu/footer/?locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getPosgradoEntities(language) {
    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=posgrado&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getPosgradoEntity(route: string, language) {
    return this.http.get(`${environment.apiUrl}/api/${route}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getIngresoEntities(language) {

    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=ingreso&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getCursoEntities(language) {
    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=educacion-continua&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getCursoEntityArea(language, area) {
    return this.http.get(`${environment.apiUrl}/api/page/routes/?page=educacion-continua/${area}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }

  getIngresoEntity(route: string, language) {
    return this.http.get(`${environment.apiUrl}/api/${route}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getEntities(route: string) {
    return this.http.get(`${environment.apiUrl}/api/${route}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getSinglePage(title: string, language: string) {
    return this.http.get(`${environment.apiUrl}/api/simplepage/get/?q=${title}&locale=${language}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  redirect(title: string) {
    return this.http.get(`${environment.apiUrl}/api/simplepage/get/?q=${title}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
  getRelationPostsPage(language: string, id: number) {
    return this.http.get(`${environment.apiUrl}/api/post/relation-posts/?locale=${language}&pageid=${id}`).pipe(tap(
      (res: any) => {
        return res;
      })
    )
  }
  /////////////////////////////////////////////

  getNoticias(): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/entitylist/?id=8&page=home`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  //borrar
  getSocialnetwork(id: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/socialnetwork/?id=${id}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  postEmblueForm(evento: any) {
    return this.http.post(emBlue.url, evento, {
      headers: {
        Authorization: 'Basic ' + btoa(emBlue.apiKey)
      }
    })
  }
  postEmblueFormPosgrados(evento: any) {
    return this.http.post(emBlue.url, evento, {
      headers: {
        Authorization: 'Basic ' + btoa(emBlue.apiKeyPosgrado)
      }
    })
  }
  postConsulta(datos: ConsultaRequest) {
    return fetch(environment.apiUrl + "/api/contact-mail/contact-form/", {
      method: 'POST',
      body: JSON.stringify(datos),
      //mode: 'no-cors',
      headers: {
        //  'Access-Control-Allow-Credentials': 'true'
      },
      redirect: 'follow'
    });


  }


  buscador(valor: string, page: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/busqueda/?search=${valor}&locale=es&page=${page}&limit=10`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getNoticiasDetalle(id: string, type: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/${type}/get/?q=${id}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getAgendaDetalle(id: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/event/get/?q=${id}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }



  getEntitylist(id: string, page: string, vanue?: boolean, venueValue?: string): Observable<any> {
    var query = '';

    if (vanue) {
      if (venueValue) {
        query = `&venue=${venueValue}`;
      } else {
        query = `&venue=${page}`;
      }

    }
    return this.http.get<any>(`${environment.apiUrl}/api/entitylist/?id=${id}${query}&page=${page}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  //borrar
  getHeadersection(id: string, page: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/headersection/?id=${id}&page=${page}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getSimplepage(q: string, d?: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/simplepage/get/?q=${q}&locale=${d}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getCustomEntity(id: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/page/custom-entity/?id=${id}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getDiary(id: string, venue: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/diary/?id=${id}&venue=${venue}&page=${venue}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getTextitem(id: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/textitem/?id=${id}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getAutoridades(tituloCarrera): Observable<any> {
    //	http://wadmin.uca.edu.ar/api/authority/?q=maestria-en-administracion-de-empresas--mba&locale=es
    return this.http.get<any>(`${environment.apiUrl}/api/authority/?q=${tituloCarrera}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getCarreras(sede?, nivel?, area?, filtro?, destacado?): Observable<any> {
    var query = '';
    if (sede) {
      query += '&regionId=' + sede;
    }
    if (area) {
      query += '&areaIdIngreso=' + area;
    }
    if (nivel) {
      query += '&type=' + nivel;
    }
    if (filtro && filtro.length>=5) {
      query += '&keyword=' + filtro;
    }
    return this.http.get<any>(`${environment.apiUrl}/api/career/search/?locale=es${query}&notGraduateDegree=0&destacado=${destacado}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getCarrera(filtro?): Observable<any> {
    var query = '';
    if (filtro) {
      query += '?q=' + filtro;
    }
    return this.http.get<any>(`${environment.apiUrl}/api/career/get-by-description/${query}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getPosgrados(sede?, nivel?, area?, palabras?,facultad?, modalidad?, venue?,destacadas?): Observable<any> {
    var query = '';
    if (sede) {
      query += '&regionId=' + sede;
    }
    if (area) {
      query += '&areaId=' + area;
    }
    if (nivel) {
      query += '&type=' + nivel;
    }
    if (palabras && palabras.length>=5) {
      query += '&keyword=' + palabras.trim();
    }
    if (facultad) {
      query += '&areaIdIngreso=' + facultad.trim();
    }
    if (modalidad) {
      query += '&modalidad=' + modalidad;
    }
    if (destacadas) {
      query += '&destacado=' + destacadas;
    } 
    if(venue){
      query += '&venueId=' + venue
    }
    return this.http.get<any>(`${environment.apiUrl}/api/career/search/?locale=es${query}&notGraduateDegree=1`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getCursos( sede?, nivel?, palabras?, area?, modalidad?, destacado?, areaTematica?,categoria?): Observable<any> {
    var query = '';
    if (sede) {
      query += '&regionId=' + sede;
    }
    if (area) {
      query += '&areaId=' + area;
    }
    if (nivel) {
      query += '&type=' + nivel;
    }
    if (palabras && palabras.length>=5) {
      query += '&keyword=' + palabras.trim();
    }
    if (modalidad) {
      query += '&modalidad=' + modalidad;
    }
    if (areaTematica) {
      query += '&areaTematica=' + areaTematica;
    }
    if (categoria) {
      query += '&sub_categoria=' + categoria;
    }
    console.log(`${environment.apiUrl}/api/career/search/?locale=es${query}&notGraduateDegree=2&tags=${destacado}`)
    return this.http.get<any>(`${environment.apiUrl}/api/career/search/?locale=es${query}&notGraduateDegree=2&tags=${destacado}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getCarreraByTitle(title: string, venue: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/career/get-by-title/?q=${title}&venue=${venue}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getCarreraByTitlePosgrados(title: string, venue: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/career/get-by-title/?q=${title}&venue=${venue}&locale=es&notGraduateDegree=1`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
 
  getCarreraProfileTitle(title: string): Observable<any> {

    return this.http.get<any>(`${environment.apiUrl}/api/career/profile-title/?q=${title}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }
  getRoutes(page: string, venue = true): Observable<any> {
    var query = '';
    if (venue) {
      query = '&isVenue=1'
    }

    return this.http.get<any>(`${environment.apiUrl}/api/page/routes/?page=${page}&locale=es${query}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getFare(q: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/fare/?q=${q}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getstudyplan(q: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/studyplan/?q=${q}&locale=es`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  byPalabaClave(filter: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/career/search/${filter}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getByThematicArea(a: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/api/career/get-by-thematic-areas/?areaId=${a}`).pipe(tap(
      (res: any) => {
        return res;
      })

    );
  }

  getFacultadByArea(area) {
    return this.http.get(`${environment.apiUrl}/api/venue/get-by-area/?area=${area}`).pipe(tap(
      (res: any) => {
        return res;
      })
    );
  }
}
