import { Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { GeneralService } from 'src/app/core/services/general/general.service';
import { environment } from 'src/environments/environment';
import { Carrera } from '../../models/observacion';
import { FormContactoComponent } from 'src/app/modules/general/components/form-contacto/form-contacto.component';
import Swal from 'sweetalert2';
import { ConsultaRequest } from '../../models/consultaRequest';
import { HeaderSectionComponent } from 'src/app/modules/general/components/header-section/header-section.component';
import { BuscadorCursosTematicoComponent } from '../buscador-cursos-tematico/buscador-cursos-tematico.component';
import { PortadaComponent } from '../portada/portada.component';
import { FormasDeContactoDosComponent } from '../formas-de-contacto/formas-de-contacto-dos.component';


@Component({
  selector: 'app-detalle-curso',
  templateUrl: './detalle-curso.component.html',
  styleUrls: ['./detalle-curso.component.scss']
})
export class DetalleCursoComponent implements OnInit {
  //general con lista 
  datos: any;
  itemsParent: any[] = [];
  links: any[];
  titleList: string;
  styleColor = '#1e3e6f';
  language: any;
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  regiones: string[] = [];
  carreras: any
  niveles: Carrera[] = [];
  areas: any[] = [];
  areasConocimiento: any[] = [];
  facultades: Carrera[] = [];
  modalidades: Carrera[] = [];
  resultados: Carrera[] = [];
  buscador: FormGroup;
  valor: string;
  count: number;
  pages: number[] = [];
  numberActive: number = 1;
  pageSize = 15;
  spinner = true;
  filtro = false;
  formDatos: FormGroup;
  type = "ingresa";
  carrer: string;
  venue: string;
  tel: Boolean = false;
  curso: any;
  dialog: any;
  carrerasArray: any
  carrerasAux: any
  id: any
  nivelPrioridad = ['Curso', 'Curso Online', 'Curso de Posgrado Online', 'Curso de Posgrado', 'Diplomatura', 'Postítulo'];
  destacadas: boolean;
  title: any;
  titulos = [
    { singular: 'Curso', plural: "Cursos" },
    { singular: 'Curso Online', plural: "Cursos Online" },
    { singular: 'Curso de Posgrado Online', plural: "Cursos de Posgrado Online" },
    { singular: 'Curso de Posgrado', plural: "Cursos de Posgrado" },
    { singular: 'Diplomatura', plural: "Diplomaturas" },
    { singular: 'Postítulo', plural: "Postítulos" },
  ];
  area = {};
  areaInfo: any


  @ViewChild('componenteDinamico1', { read: ViewContainerRef }) viewContainerRef1: ViewContainerRef;
  @ViewChild('componenteDinamico2', { read: ViewContainerRef }) viewContainerRef2: ViewContainerRef;
  @ViewChild('componenteDinamico3', { read: ViewContainerRef }) viewContainerRef3: ViewContainerRef;
  @ViewChild('componenteDinamico4', { read: ViewContainerRef }) viewContainerRef4: ViewContainerRef;
  @ViewChild('componenteDinamico5', { read: ViewContainerRef }) viewContainerRef5: ViewContainerRef;
  @ViewChild('componenteDinamico6', { read: ViewContainerRef }) viewContainerRef6: ViewContainerRef;
  @ViewChild('componenteDinamico7', { read: ViewContainerRef }) viewContainerRef7: ViewContainerRef;
  @ViewChild('componenteDinamico8', { read: ViewContainerRef }) viewContainerRef8: ViewContainerRef;
  @ViewChild('componenteDinamico9', { read: ViewContainerRef }) viewContainerRef9: ViewContainerRef;
  @ViewChild('componenteDinamico10', { read: ViewContainerRef }) viewContainerRef10: ViewContainerRef;
  @ViewChild('componenteDinamico11', { read: ViewContainerRef }) viewContainerRef11: ViewContainerRef;
  @ViewChild('componenteDinamico12', { read: ViewContainerRef }) viewContainerRef12: ViewContainerRef;
  @ViewChild('componenteDinamico13', { read: ViewContainerRef }) viewContainerRef13: ViewContainerRef;
  @ViewChild('componenteDinamico14', { read: ViewContainerRef }) viewContainerRef14: ViewContainerRef;
  @ViewChild('componenteDinamico15', { read: ViewContainerRef }) viewContainerRef15: ViewContainerRef;
  @ViewChild('componenteDinamico16', { read: ViewContainerRef }) viewContainerRef16: ViewContainerRef;
  @ViewChild('componenteDinamico17', { read: ViewContainerRef }) viewContainerRef17: ViewContainerRef;
  @ViewChild('componenteDinamico18', { read: ViewContainerRef }) viewContainerRef18: ViewContainerRef;
  @ViewChild('componenteDinamico19', { read: ViewContainerRef }) viewContainerRef19: ViewContainerRef;
  @ViewChild('componenteDinamico20', { read: ViewContainerRef }) viewContainerRef20: ViewContainerRef;
  areaUrl = "";

  titulo: any
  subitulo: any
  itemName: string;
  tieneTyS: boolean = true

  infoAreaRecomendada: any


  constructor(private aRouter: ActivatedRoute, private services: GeneralService, public route: ActivatedRoute, private form: FormBuilder, private componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.aRouter.params.forEach((params: Params) => {
      this.language = params['language'];
    });


    var url = decodeURIComponent(window.location.href);
    var id = url.replace(environment.frontUrl + '/' + this.language + '/', '');
    var segments = id.split('/');
    if (segments.length > 1) {
      this.areaUrl = segments[1].split('?')[0];
    }
    this.services.getSimplepage(id, this.language).subscribe(res => {
      if (res.data[0]) this.area = res.data[0].area_tematica;
    })
    ///

    this.buscador = this.form.group({
      sede: ['',],
      area: ['',],
      nivel: ['',],
      modalidad: ['',],
      facultad: ['',],
      palabras: ['',],
      venueId: ['',],
    });
    this.formDatos = this.form.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      region: ['', [Validators.required]],
      //phone: ['', [Validators.pattern(Patters.OnlyNumber)]],
      message: [''],
    });



  }

  ngAfterViewInit() {
    var url = location.href;
    var indexL = url.indexOf(this.language) + this.language.length + 1;

    this.services.getCursoEntityArea(this.language, this.areaUrl).subscribe(data => {
      Object.keys(data.data[0]).forEach((key) => {
        var arrayValue = data.data[0];
        if (!isNaN(Number(key))) {
          this.services.getEntities(arrayValue[key][0].route + '&locale=' + this.language).subscribe(data1 => {
            this.definirComponentes(Number(key), arrayValue[key][0], 8, data1)
          });
        }
      });
    });



  }

  refBuscador: any
  definirComponentes(index: number, element: any, width: number, data1: any) {
    let info = data1.data;
    switch (element.entityType) {
      case "HeaderSection":
        this.newComponent(index, 'HeaderSection', info, width)

        break;
      case "Portada":
      case "PortadaAreas":
        this.newComponent(index, 'Portada', info, width)
        break;
      case "AreaRecomendada":
        if (this.refBuscador) {
          this.refBuscador.instance.infoAreaRecomendada = info;
          this.refBuscador.changeDetectorRef.detectChanges();
        }

        break
      case "Buscador":
        this.newComponent(index, 'Buscador', info, width)
        break

      case "Contacto":
        this.newComponent(index, "Contacto", info, width)
        break;
      case "CustomPublication":
        this.newComponent(index, 'CustomPublication', info, width)

        break;

      case "TextItem":
        this.newComponent(index, 'TextItem', info, width)
        break;

      case 'EntityList':
        info.entityList.forEach(element => {
          let type = element.typeDisplay;
          var background = element.background;
          this.newComponent(index, type, info, width, background)
        });

        break;
      case "Diary":

        this.newComponent(index, 'Diary', info, width)

        break;
    }

  }

  newComponent(index: number, type: string, info: any, width: number, background?: string) {
    switch (type) {
      case "Portada": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(PortadaComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        // ref.location.nativeElement.classList.add('col-lg-' + width);
        this.titulo = info[0].cover.title
        this.subitulo = info[0].cover.subtitle
        ref.instance.area= this.area;
        ref.instance.tieneTyS = this.tieneTyS
        ref.instance.data = info[0];
        ref.instance.marginTitle = 75;
        ref.instance.heightDesk = "300px";
        ref.changeDetectorRef.detectChanges();

      }
        break;
      case 'HeaderSection': {
        let factory = this.componentFactoryResolver.resolveComponentFactory(HeaderSectionComponent);
        let ref2 = (this.viewContainerRefA(index)).createComponent(factory);
        ref2.location.nativeElement.classList.add('col-lg-' + width);
        ref2.instance.pathMob = info.headerSection.pathMobile;
        ref2.instance.path = info.headerSection.path;
        ref2.instance.language = this.language
      }
        break;

      case 'Buscador': {

        let factory = this.componentFactoryResolver.resolveComponentFactory(BuscadorCursosTematicoComponent);
        this.refBuscador = (this.viewContainerRefA(index)).createComponent(factory);
        this.refBuscador.instance.titulo = "Educación continua";
        this.refBuscador.instance.translateY = 75;
        this.refBuscador.instance.padding = "38.6px 35px";
        this.refBuscador.instance.paddingMob = "35px";
        this.refBuscador.instance.infoAreaRecomendada = this.infoAreaRecomendada;
        this.refBuscador.instance.data = info;
        this.refBuscador.instance.area= this.area;
        this.refBuscador.changeDetectorRef.detectChanges();

        break;
      }

      case "Contacto": {
        let factory = this.componentFactoryResolver.resolveComponentFactory(FormasDeContactoDosComponent);
        let ref = (this.viewContainerRefA(index)).createComponent(factory);
        ref.instance.data = info;
        ref.instance.fondo = "Fondo-web-continua.png";
        ref.instance.esCurso = true

      }
        break;
      /*  case 'Simple': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         if (width == 8) {
           ref.instance.numberCards = 3;
         }
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.instance.background = background;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'CustomPublication': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.publication;
         ref.instance.title = 'Publicaciones';
         ref.instance.background = background;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'Noticias con resumen': {
         let factory = this.componentFactoryResolver.resolveComponentFactory(PostComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.entityList[0].entity;
         ref.instance.title = info.entityList[0].title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
       }
 
         break;
       case 'Carrousel':
         if (info.entityList[0].entity) {
           let factory = this.componentFactoryResolver.resolveComponentFactory(CarrouselGeneralComponent);
           let ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.data = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'Lista desplegable':
         if (info.entityList[0].entity) {
           let factory = this.componentFactoryResolver.resolveComponentFactory(ListDesplegableComponent);
           let ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.items = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.changeDetectorRef.detectChanges();
         }
         break;
       case 'Cards':
         let factory = this.componentFactoryResolver.resolveComponentFactory(CardsGeneralDosComponent);
         let ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.socialNetwork.socialNetworkItem;
         ref.instance.title = info.socialNetwork.title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
 
         break;
       case 'Lista de links':
         if (info.entityList[0].entity) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(ListaDeLinksComponent);
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.data = info.entityList[0].entity;
           ref.instance.title = info.entityList[0].title;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.background = background;
           ref.changeDetectorRef.detectChanges();
         }
         break;
 
       case 'Video + Formulario (en ingles)':
         if (info.entityList[0].linkCode) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
           );
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.linkCode = info.entityList[0].linkCode;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.instance.idioma = 'en';
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'Video + Formulario (en español)':
         if (info.entityList[0].linkCode) {
           const factory = this.componentFactoryResolver.resolveComponentFactory(VideoMasFormularioComponent
           );
           const ref = (this.viewContainerRefA(index)).createComponent(factory);
           ref.instance.linkCode = info.entityList[0].linkCode;
           ref.location.nativeElement.classList.add('col-lg-' + width);
           ref.instance.title = info.entityList[0].title;
           ref.instance.background = background;
           ref.instance.idioma = 'es';
           ref.changeDetectorRef.detectChanges();
 
         }
         break;
       case 'TextItem': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(TextItemGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.instance.data = info.title;
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.i = index;
         ref.changeDetectorRef.detectChanges();
 
       }
         break; 
 
       case 'CustomForm': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(FormContactoComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
       case 'Diary': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CustomDiaryGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         if (index == 2) {
           width = 4;
         }
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.data = info.diary[0].diaryItem;
         ref.instance.title = info.diary[0].title;
         ref.instance.width = width;
         ref.instance.botonTitle = "Eventos de la facultad";
         ref.changeDetectorRef.detectChanges();
 
 
       }
         break;
       case 'CustomStats': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CustomStatsGeneralComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.data = info.CustomStats;
         ref.instance.width = width;
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
       case 'Cabecera': {
         const factory = this.componentFactoryResolver.resolveComponentFactory(CabeceraComponent);
         const ref = (this.viewContainerRefA(index)).createComponent(factory);
         ref.location.nativeElement.classList.add('col-lg-' + width);
         ref.instance.title = info.entityList[0].title;
         ref.instance.slider = info.entityList[0].entity;
         ref.instance.background = info.entityList[0].background;
         ref.instance.path = info.entityList[0].path;
         ref.instance.width = width;
         ref.instance.noticesLinkTitle = info.entityList[0].noticesLinkTitle;
         ref.instance.noticesLink = info.entityList[0].noticesLink.systemTitle;
         ref.instance.cabeceraButtons = info.entityList[0].cabeceraButtons;
         ref.changeDetectorRef.detectChanges();
 
       }
         break;
 
  */
    }






  }

  viewContainerRefA(index: number): any {
    switch (index) {
      case 1:
        return this.viewContainerRef1;
      case 2:
        return this.viewContainerRef2;
      case 3:
        return this.viewContainerRef3;
      case 4:
        return this.viewContainerRef4;
      case 5:
        return this.viewContainerRef5;
      case 6:
        return this.viewContainerRef6;
      case 7:
        return this.viewContainerRef7;
      case 8:
        return this.viewContainerRef8;
      case 9:
        return this.viewContainerRef9;
      case 10:
        return this.viewContainerRef10;
      case 11:
        return this.viewContainerRef11;
      case 12:
        return this.viewContainerRef12;
      case 13:
        return this.viewContainerRef13;
      case 14:
        return this.viewContainerRef14;
      case 15:
        return this.viewContainerRef15;
      case 16:
        return this.viewContainerRef16;
      case 17:
        return this.viewContainerRef17;
      case 18:
        return this.viewContainerRef18;
      case 19:
        return this.viewContainerRef19;
      case 20:
        return this.viewContainerRef20;
      default:
        // 
        break;
    }
  }


  convertirObjetoAArray(data: any) {
    for (const key in data) {
      if (data.hasOwnProperty(key) && !isNaN(Number(key))) {
        this.areas.push(data[key] as any);
      }
    }
  }

  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  redirect(c) {
    if (c.route) {
      window.location.href = this.language + c.route
    } else if (c.linkCareer) {
      window.location.href = this.language + c.linkCareer
    }

  }

  getCarrerasKeys(): string[] {
    return Object.keys(this.carreras);
  }

  consultar() {
    let datos = new ConsultaRequest();
    datos.email = this.formDatos.get('email').value;
    datos.name = this.formDatos.get('name').value;
    datos.region = this.formDatos.get('region').value;
    datos.isHome = false;
    datos.message = this.formDatos.get('message').value;
    datos.career = this.carrer;
    if (datos.venue && datos.career) {
      this.consultarEmblue(datos);
    }
    this.services.postConsulta(datos).then(function (response) {
      return response.json();
    }).then(res => {
      if (res.status == 1) {
        this.formDatos.reset()
        Swal.fire(
          'La consulta fue enviada con exito!',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'La consulta fallo , lo sentimos',
          '',
          'error'
        )

      }
    }
    )


  }
  consultarEmblue(datos: ConsultaRequest) {
    let atributos = (
      {
        career: carrera,
        name: nombre,
        phone: whatsapp,
        region: sede
      }
    ) => ({ carrera, nombre, whatsapp, sede });
    let eventoEmblue = {
      eventName: 'form_derecho',
      email: datos.email,
      attributes: atributos(datos)
    };
    if (datos.venue) {
      switch (datos.venue) {
        case "facultad-de-derecho":
          eventoEmblue.eventName = "form_derecho"
          break;
        case "facultad-de-psicologia-y-psicopedagogia":
          eventoEmblue.eventName = "form_psico"
          break;
        case "facultad-de-ciencias-medicas":
          eventoEmblue.eventName = "form_cs.medicas"
          break;
        case "facultad-de-ingenieria-y-ciencias-agrarias":
          eventoEmblue.eventName = "form_ingeniería"
          break;
        case "facultad-de-artes-y-ciencias-musicales":
          eventoEmblue.eventName = "form_musica"
          break;
        case "facultad-de-filosofia-y-letras":
          eventoEmblue.eventName = "form_filo"
          break;
        case "facultad-de-ciencias-economicas":
          eventoEmblue.eventName = "form_economicas"
          break;
        case "facultad-de-ciencias-sociales":
          eventoEmblue.eventName = "form_sociales"
          break;

        default:
          eventoEmblue.eventName = "form_derecho"
          break;
      }
    }

    if (this.type == "posgrados") {
      this.services.postEmblueFormPosgrados(eventoEmblue).subscribe(
        (data) => console.log({ message: ' Form emblue Posgrados', data: data }),
        error => console.log({ message: ' Form emblue Posgrados', error: error })
      )
    } else {
      this.services.postEmblueForm(eventoEmblue).subscribe(
        (data) => console.log({ message: 'Form emblue ', data: data }),
        error => console.log({ message: ' Form emblue', error: error })
      )
    }

  }
  openModal(): void {
    let dialogRef = this.dialog.open(FormContactoComponent, { panelClass: 'custom-dialog-container', data: { curso: null } });
    const sub = dialogRef.componentInstance.closeModal.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  windowOpen(url) {
    window.open(url)
  }
  updateFilter(field: string, value: any) {
    this.buscador.get(field).setValue(value);
    //this.filtrar();
  }

  cortarTitulo(titulo) {
    if (titulo.length > 50) {
      return titulo.slice(0, 50) + "...";
    } else {
      return titulo;
    }
  }
  cambiarPagina(page) {
    var modalidad = this.buscador.get('modalidad').value;
    var palabra = this.buscador.get('palabras').value;

    this.pages = [];
    let resultadoFiltrado = this.filtros(modalidad);
    this.resultados = resultadoFiltrado.slice(((page - 1) * this.pageSize), (page) * this.pageSize)
    let cant = Math.ceil(this.carreras.length / this.pageSize)

    for (let i = 0; i < cant; i++) {
      this.pages.push(i + 1);
    }

    this.numberActive = page;
    page = (page == '...') ? (this.numberActive) : page;
    this.spinner = false;

  }
  primerPaginaHabilitada(): boolean {
    return this.numberActive > 1;
  }

  ultimaPaginaHabilitada(): boolean {
    return this.numberActive < this.pages.length;
  }

  get rangoResultados(): string {
    const startIndex = (this.numberActive - 1) * this.pageSize + 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.carreras.length);
    return `${startIndex}-${endIndex} de ${this.carreras.length}`;
  }


  filtros(modalidad: string): any[] {
    let listaFiltrada = this.carrerasAux;
    if (modalidad) {
      listaFiltrada = listaFiltrada.filter(r => {
        if (r.modalidad) {
          return this.processText(r.modalidad) === this.processText(modalidad);
        } /* else {
          return true; 
        } */
      });
    }

    listaFiltrada.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);

      const difA = Math.abs(Math.ceil((fechaA.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));
      const difB = Math.abs(Math.ceil((fechaB.getTime() - new Date().getTime()) / (1000 * 3600 * 24)));

      if (difA < difB) {
        return -1;
      } else if (difA > difB) {
        return 1;
      } else {
        return fechaA.getTime() - fechaB.getTime();
      }
    });
    return listaFiltrada;
  }

  getInicio(mes: number, año: number): string {
    return this.meses[mes] + ' ' + año;
  }

  processText(palabra: string): string {
    palabra = palabra.trim();
    palabra = palabra.toLocaleLowerCase();

    return palabra;
  }

  formatearFecha(value: any): any {
    if (value) {
      const date = new Date(value);
      const monthNames = [
        "enero", "febrero", "marzo",
        "abril", "mayo", "junio", "julio",
        "agosto", "septiembre", "octubre",
        "noviembre", "diciembre"
      ];

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];

      return `${day} de ${monthName}`;
    } else {
      return 'a confirmar'
    }

  }
  ngOnInit(): void {

    this.itemName = localStorage.getItem('itemName');
  }
  url(element: any) {
    let url = '';
    let boolExternalLink: any = element.externalLink;
    if (!boolExternalLink) {
      if (element.route) {
        url = element.route
        if (element.route[0] == '/') {
          url = element.route = element.route.replace('/', '')
        }
        url = '/es/' + url;
      }
      if (element.systemTitle) {
        url = element.systemTitle
        if (element.systemTitle[0] == '/') {
          url = element.systemTitle = element.systemTitle.replace('/', '')
        }
        url = '/es/' + url;
      }
    } else {
      url = element.route;
    }

    return url;
  }
  eliminarPrefijoFacultad(cadena: string): string {
    const prefijo = 'Facultad de';
    if (cadena && cadena.startsWith(prefijo)) {
      return cadena.substring(prefijo.length).trim();
    } else {
      return cadena;
    }
  }
}
