<div class="fondo">
    <div style="text-align: -webkit-center;">
        <div id="buscador" class="buscador-container" [style.background-color]="data.background_color">
            <h1 class="titulo-buscador quicksand-medium" [style.color]="data.color_title">{{data.title}}</h1>
            <h5 class="subtitulo-buscador quicksand-medium" [style.color]="data.color_subtitle">{{data.subtitle}}
            </h5>



            <form [formGroup]="buscador" class="form-group" id="busqueda" style='justify-content: space-between;'>

                <div style="display: flex;
                flex-wrap: wrap; width: 100%; justify-content: space-between;">
                    <div class="input-container">
                        <input #filtroInput autofocus type="text" formControlName='filtro' (keyup)="(filtroInput.value.length >= 5 || filtroInput.value.length==0)? filtrar($event) : null"

                            class="form-control-c nunito-medium " placeholder="Palabra clave">
                        <img class="buscar" src="../../../../../assets/img/buscar-azul.png" alt="">
                        <!--  <div class="buscar-icon"></div>          -->
                    </div>

                    <div class="input-container">

                        <app-custom-select [options]="areas" placeholder="Elegí el área" placeholderAll="Todas las áreas"
                            (selectionChange)="updateFilter('area', $event)" [ingreso]="true" [id]="'select-area'">
                        </app-custom-select>
                    </div>
                    <!--<div class="input-container">
                        <app-custom-select [options]="categorias" placeholder="Elegí la categoría"
                          (selectionChange)="updateFilter('categoria', $event)" [ingreso]="true" [curso]="true">
                        </app-custom-select>
                      </div>-->
                    <!--<div class="input-container">

                        <app-custom-select [options]="niveles" placeholder="Elegí el nivel"
                            (selectionChange)="updateFilter('nivel', $event)" [ingreso]="true" [id]="'select-nivel'">
                        </app-custom-select>
                    </div>-->
                    <div *ngIf="mostrarSede" class="input-container">

                        <app-custom-select [options]="regiones" placeholder="Elegí la sede" placeholderAll="Todas las sedes"
                            (selectionChange)="updateFilter('sede', $event)" [ingreso]="true" [id]="'select-sede'">
                        </app-custom-select>
                    </div>
                    <div class="input-container" style=" margin-right: 0px !important;">

                        <app-custom-select [options]="modalidades" placeholder="Modalidad" placeholderAll="Todas las modalidades"
                            (selectionChange)="updateFilter('modalidad', $event)" [ingreso]="true"
                            [id]="'select-modalidad'">
                        </app-custom-select>
                    </div>

                </div>

            </form>
        </div>
    </div>
    <div class="resultados  " id="resultados" style="    text-align: -webkit-center;">
        <ul *ngIf='!spinner' style="    padding-inline-start: 0px !important">
           

            <div class="container-resultados-cursos">
                <ng-container *ngFor='let carrera of resultados; let i = index'>
                    <app-card-overlay2 [carrera]="carrera" [borde]="true"
                        [busqueda]="pages.length > 1"></app-card-overlay2>
                </ng-container>
            </div>


        </ul>
        <div class="d-flex justify-content-center spinner" *ngIf='spinner'>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>


        <div class="paginacion" *ngIf='resultados.length > 0 && pages.length > 1 && !spinner'>
            <ng-container *ngFor="let page of visiblePages2">
                <div class="number" [ngClass]='(numberActive == page) ? "active" : ""' *ngIf="page !== '...'"
                    (click)='cambiarPagina(page)'>
                    {{ page }}
                </div>
                <div class="dots" *ngIf="page === '...'">
                    {{ page }}
                </div>
            </ng-container>
        </div>
        <div *ngIf=' resultados.length == 0 && !spinner && !destacadas' class='p-3 text-center no-result'>
            <app-areas-recomendadas [infoAreaRecomendada]="infoAreaRecomendada"></app-areas-recomendadas>
        </div>


        <!--<div *ngIf=' resultados.length == 0 && !spinner && !destacadas' class='p-3 text-center no-result'>
            <div class="alert alert-primary text-center" role="alert">
                No hay resultados
            </div>
        </div>-->
    </div>
</div>