
<div class="nuestras-sedes">
  <div class="sedes-container" [style.background-color]="backgroundColor">
    <h1 class="sedes-titulo quicksand-regular">{{title}}</h1>
  </div>
    <div class="sede-container">
      <div id="sede" class="sede-d" *ngFor="let item of sedes">
        <div class="sede" >
          <div class="sede-overlay" [routerLink]="item.redirec">
            <img src="./assets/img/{{item.img}}" alt="" class="img">
            <div class="overlay-text">
              <p class="overlay-t quicksand-regular">{{item.title}}</p>
              <p class=" overlay-t text text-small nunito-medium">{{label}}</p>
              <a [routerLink]="item.redirec" class="info"  [style.background-color]="btnColor">
                <p  class="  overlay-t  nunito-medium text-small">MÁS INFO >></p>
            </a>
            </div>
            
          </div>
        </div>
  
        <div class="direc nunito-regular ">
          <a [href]="redirecGoogleMaps(item.direccion)" target="_blank" style="color: #003269;" class="nunito-regular">{{item.direccion}}</a>
          <!-- <p>{{item.direccion}}</p> -->
          <!-- <p>{{item.direccion2}}</p>
          <p>{{item.direccion3}}</p> -->
          <div class="container-contactanos">
            <div class='iconos'>
              <a id="redes"  *ngIf="item.title !='Rosario'" style='cursor:pointer;color: #007bff;'
                (click)='windowOpen(item.facebook)'>
                <img onerror="this.src='./assets/img/default.png'" class='icon-redes'
                  src="../../../../../assets/img/fb2.png" alt="">
              </a>
              <a id="redes"  style='cursor:pointer;color: #007bff;' (click)='windowOpen(item.instagram)'>
                <img onerror="this.src='./assets/img/default.png'" class='icon-redes'
                  src="../../../../../assets/img/ig2.png" alt="">
              </a>
              <!-- <a style='cursor:pointer;color: #007bff;' (click)='windowOpen(item.whatsapp)'>
                <img onerror="this.src='./assets/img/default.png'" class='icon'
                  src="../../../../../assets/img/wp.png" alt="">
              </a> -->
            </div>
            <div>
              <button class="btn-green nunito-regular " (click)="redirecWhatsApp(item.tel)"> CONTACTANOS
                <img onerror="this.src='./assets/img/default.png'" class='icon'
                src="../../../../../assets/img/whsatsapp-blanco.png" alt=""> 
              </button>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  
</div>