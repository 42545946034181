
<!-- NUEVA CARD -->

<div id="resultado-buscador"  class="sede2 d-flex flex-column borde-nuevo" (click)="redirect(carrera)"
  [style.border-color]="(carrera.area_tematica) ? carrera.area_tematica?.color : carrera.color">
 
  <div class="icon-container" 
    [style.background-color]="(carrera.area_tematica) ? carrera.area_tematica?.color : carrera.color">
    <p *ngIf="carrera.sub_categorias"
      [style.color]="carrera.area_tematica ? carrera.area_tematica?.color_subcategoria : 'white'" class="curso-text">
      {{carrera.sub_categorias?.nombre}}
    </p>
  </div>
  <div class="img-container" [style.border-color]="carrera.color">
    <img *ngIf="carrera.pathMobile" [src]="carrera.pathMobile" alt="" class="img-carrera"
      onerror="this.src='../../../../assets/img/default.png'">
    <img *ngIf="!carrera.pathMobile" src="../../../../assets/img/default.png" alt="" class="img-carrera">

  </div>
  <div class="card-text-container flex-grow-1 d-flex flex-column">
    <div class="flex-grow-1 ">
      <p [title]="carrera.title" class="text text-large2 quicksand-medium text-left"
        style="margin-bottom: 0px !important;">{{ cortarTitulo(carrera.title) }}</p>
    </div>


    <div class="d-flex flex-column align-items-start">
      <p class="text text-small nunito-regular  sede-chico " [style.color]="(carrera.area_tematica) ? carrera.area_tematica?.color : carrera.color"
        style="margin-bottom: 0px !important; "><span class="nunito-bold">INICIO : </span>{{ formatearFecha(carrera) }}
      </p>
      <p class="text text-small2 nunito-regular sede-chico" style="margin-bottom: 0px;"><span class="nunito-bold">SEDE:
        </span>{{carrera.sede}}</p>
    </div>

    <div class="d-flex w-100 justify-content-between align-items-center">
      <a [href]="language +carrera.linkCareer">
        <p class="info2 nunito-regular">MÁS INFO >></p>
      </a>
      <div class="modalidad-container">
        <p class="text text-medium nunito-regular" style="display: flex; margin-bottom: auto; ">{{carrera.modalidad}}

        </p>
        <ng-container *ngIf="carrera.modalidad == 'Presencial'">
          <img src="../../../../assets/img/presencial-gris.png" alt="" class="icon-modalidad2">

        </ng-container>

        <ng-container *ngIf="carrera.modalidad == 'Virtual'">
          <img src="../../../../assets/img/online-gris.png" alt="" class="icon-modalidad2">
        </ng-container>
        <ng-container *ngIf="carrera.modalidad == 'Híbrida'" style="display: flex;">
          <img src="../../../../assets/img/presencial-gris.png" alt="" class="icon-modalidad2">
          <img src="../../../../assets/img/online-gris.png" alt="" class="icon-modalidad2">


        </ng-container>
      </div>
    </div>


  </div>


</div>