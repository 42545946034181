import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-card-area-conocimiento',
  templateUrl: './card-area-conocimiento.component.html',
  styleUrls: ['./card-area-conocimiento.component.scss']
})
export class CardAreaConocimientoComponent implements OnInit {
  @Input() item: any;
  @Input() url: any;
  @Input() page: any;
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  svgContent: SafeHtml | null = null;

  createLink(item) {
    let url = environment.apiUrl + item
    return url
  }
  setItemName(url: any) {
    setTimeout(() => {
      this.url = window.location.href = '/es/' + url;
    }, 10);

  }
  ngOnInit() {
    if (this.item.url) this.item.link = this.item.url;
    if (this.item.file && this.isSvg(this.item.file)) {
      this.loadSvg(this.item.file);
    }
  }

  isSvg(file: string): boolean {
    return file.endsWith('.svg');
  }

  loadSvg(file: string): void {
    this.http.get(this.createLink(file), { responseType: 'text' }).subscribe(svg => {
      const modifiedSvg = this.changeSvgColor(svg, this.item.color);
      this.svgContent = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/svg+xml,' + encodeURIComponent(modifiedSvg));
    });
  }

  changeSvgColor(svg: string, color: string): string {
    return svg.replace(/fill: #[0-9a-fA-F]{6};/g, `fill: ${color};`);
  }
}
